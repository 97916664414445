/** @jsxImportSource @emotion/react */
import { Icon } from "../../assets/img/img";
import useHandleHistory from "../../hooks/useHandleHistory";
import useResponsive from "../../hooks/useResponsive";
import { useState } from "react";
import { ButtonImg } from "../../assets/Button/buttons";

export default function Footer() {
  const { handleHistoryPush } = useHandleHistory();
  const { isTablet, isMobile } = useResponsive();
  const [logoSrc, setLogoSrc] = useState<string>(Icon.로고);
  const [buttonSrc, setButtonSrc] = useState<string>(ButtonImg.contact2);

  return (
    <div css={isfooterMobile(isMobile)}>
      <div css={isFooterMobile(isMobile, isTablet)}>
        <div
          css={logo}
          onClick={() => {
            handleHistoryPush("/");
          }}
          onMouseEnter={() => setLogoSrc(Icon.호버로고)}
          onMouseLeave={() => setLogoSrc(Icon.로고)}
        >
          <img src={logoSrc} alt="Logo" />
        </div>
        <div css={isButtonAreaMobile(isMobile)}>
          <div css={isInfoTablet(isTablet)}>
            <div css={isInfoGap(isTablet)}>
              <p
                css={[textBorder, pointer]}
                onClick={() => {
                  handleHistoryPush("/Medical");
                }}
              >
                MEDICAL
              </p>
              <p
                css={[textBorder, pointer]}
                onClick={() => {
                  handleHistoryPush("/AiData");
                }}
              >
                AI DATA
              </p>
              <p
                css={[textBorder, pointer]}
                onClick={() => {
                  handleHistoryPush("/Recycle");
                }}
              >
                RECYCLE
              </p>
            </div>
            <div css={informationGap}>
              <p css={textBorder}>Contect Us</p>
              <div css={textGap}>
                <p css={textStandard}>
                  <span css={spanGap}>문의</span> seoreu@seoreu.com
                </p>
                <p css={textStandard}>
                  <span css={spanGap}>전화</span> 070.5066.0525
                </p>
                <p css={textStandard}>
                  <span css={spanGap}>팩스</span> 070.7500.0680
                </p>
              </div>
            </div>
            <div css={informationGap}>
              <p css={textBorder}>Office</p>
              <div css={textGap}>
                <p css={textStandard}>
                  <span css={spanGap}>부산</span>부산광역시 동구 망양로 978, 403-404호 (48722)
                </p>
                <p css={textStandard}>
                  <span css={spanGap}>양산</span>경상남도 양산시 물금읍 부산대학로 49, 203-1호 (50612)
                </p>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => setButtonSrc(ButtonImg.contact2Hover)}
            onMouseLeave={() => setButtonSrc(ButtonImg.contact2)}
            css={{ height: "fit-content" }}
          >
            <img
              onClick={() => {
                handleHistoryPush("/Contact");
              }}
              src={buttonSrc}
              alt="contact"
              css={buttonImage}
            />
          </div>
        </div>
      </div>
      <div css={isAdditionalMobile(isMobile, isTablet)}>
        <div css={isPersonaInfoSize(isTablet)}>
          <p css={CopyrightText}>Copyright © 2024 SEOREU, Inc. All right reserved.</p>
          <a
            href="https://drive.google.com/file/d/12hcpG81GtGmwilNdrf8Awm9XjrPoCtIe/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p css={PersonaText}>개인정보 처리방침</p>
          </a>
        </div>
        <div css={LinkArea}>
          <a href="https://www.youtube.com/@seoreu6610" target="_blank" rel="noopener noreferrer">
            <img src={Icon.유튜브} alt="유튜브" />
          </a>
          <a href="https://blog.naver.com/seoreu_data" target="_blank" rel="noopener noreferrer">
            <img src={Icon.네이버} alt="네이버" />
          </a>
          <a href="https://www.instagram.com/seoreu_official/" target="_blank" rel="noopener noreferrer">
            <img src={Icon.인스타그램} alt="인스타그램" />
          </a>
        </div>
      </div>
    </div>
  );
}

const footer = {
  backgroundColor: "#1d1d1d",
  padding: "91px 60px 0",
};

const footerMobile = {
  ...footer,
  padding: "30px 0",
};

const isfooterMobile = (isMobile: boolean) => (isMobile ? footerMobile : footer);

const footerContent = {
  padding: "0 352px 120px",
  borderBottom: "0.5px solid #a1a1aa",
};

const footerContentTablet = {
  ...footerContent,
  padding: "0 80px 40px",
};

const footerContentMobile = {
  ...footerContentTablet,
  padding: "0 20px 30px",
};

const isFooterTablet = (isTablet: boolean) => (isTablet ? footerContentTablet : footerContent);
const isFooterMobile = (isMobile: boolean, isTablet: boolean) =>
  isMobile ? footerContentMobile : isFooterTablet(isTablet);

const logo = {
  width: "fit-content",
  marginBottom: "30px",
  cursor: "pointer",
};

const ButtonArea = {
  display: "flex",
  flexDirection: "row" as const,
  justifyContent: "space-between",
};

const ButtonAreaMobile = {
  ...ButtonArea,
  flexDirection: "column" as const,
  gap: "30px",
};

const isButtonAreaMobile = (isMobile: boolean) => (isMobile ? ButtonAreaMobile : ButtonArea);

const textBorder = {
  color: "white",

  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "21.48px",
  textAlign: "left" as const,
};

const textStandard = {
  color: "white",

  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "17.9px",
  textAlign: "left" as const,
};

const information = {
  display: "flex",
  flexDirection: "row" as const,
  gap: "100px",
};

const informationTablet = {
  ...information,
  flexDirection: "column" as const,
  gap: "30px",
};

const isInfoTablet = (isTablet: boolean) => (isTablet ? informationTablet : information);

const informationGap = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "30px",
};

const informationGapTablet = {
  ...information,
  gap: "20px",
};

const isInfoGap = (isTablet: boolean) => (isTablet ? informationGapTablet : informationGap);

const textGap = {
  display: "flex",
  flexDirection: "column" as const,
  gap: "15px",
};

const spanGap = {
  marginRight: "15px",
};

const Additional = {
  display: "flex",
  justifyContent: "space-between",
  padding: "41px 352px",
};

const AdditionalTablet = {
  ...Additional,
  padding: "20px 80px",
};

const AdditionalMobile = {
  ...AdditionalTablet,
  padding: "20px 30px",
  flexDirection: "column" as const,
  gap: "15px",
};

const isAdditionalSize = (isTablet: boolean) => (isTablet ? AdditionalTablet : Additional);
const isAdditionalMobile = (isMoblie: boolean, isTablet: boolean) =>
  isMoblie ? AdditionalMobile : isAdditionalSize(isTablet);

const PersonalInfo = {
  display: "flex",
  flexDirection: "row" as const,
  gap: "20px",
};

const PersonalInfoTablet = {
  ...PersonalInfo,
  flexDirection: "column" as const,
  gap: "5px",
};

const isPersonaInfoSize = (isTablet: boolean) => (isTablet ? PersonalInfoTablet : PersonalInfo);

const CopyrightText = {
  color: "#a1a1aa",

  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "17.9px",
  textAlign: "left" as const,
};

const PersonaText = {
  color: "white",
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "17.9px",
  textAlign: "left" as const,
  cursor: "pointer",
  ":hover": {
    textDecoration: "underline",
  },
};

const LinkArea = {
  display: "flex",
  flexDirection: "row" as const,
  gap: "15px",
};

const pointer = {
  cursor: "pointer",
  ":hover": {
    textDecoration: "underline",
  },
};

const buttonImage = {
  width: "120px",
  height: "40px",
  cursor: "pointer",
};
